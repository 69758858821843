import React from "react"
import { Link, graphql } from "gatsby"
import unified from "unified"
import parse from "rehype-parse"
import rehypeReact from "rehype-react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Imgix from "react-imgix"

const BlogPostTemplate = ({ data, location }) => {
  const post = data.microcmsBlog
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const htmlAsset = unified()
    .use(parse, {fragment: true})
    .parse(post.content)
  const renderAsset = new rehypeReact({
    createElement: React.createElement,
    fragment: React.fragment,
    components: {
      img: props => {
        return (
          <Imgix
            src={props.src}
            width="100%"
            htmlAttributes={{
              alt: props.alt,
            }}
          />
        )
      }
    }
  }).Compiler

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.title}
        description={post.title}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
        <Imgix
            src={post.eyecatch.url}
            width="100%"
            htmlAttributes= {{
              alt: "",
            }}
          />
          <h1 itemProp="headline">{post.title}</h1>
          <p>{post.publishDate}</p>
          { post.category.map( cat => (
            <li >
              <Link to={`/blog/cat/${cat.categorySlug}/`}>{cat.category}</Link>
            </li>
          ))
          }
        </header>
        <section itemProp="articleBody">
          {renderAsset(htmlAsset)}
        </section>
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to= {`/blog/post/${previous.slug}/`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={`/blog/post/${next.slug}/`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    microcmsBlog(id: {eq: $id}) {
      id
      title
      content
      publishDate(formatString: "YYYY-MM-DD")
      eyecatch {
        url
      }
      category {
        category
        categorySlug
      }
    }
    previous: microcmsBlog(id: { eq: $previousPostId }) {
      slug
      title
    }
    next: microcmsBlog(id: { eq: $nextPostId }) {
      slug
      title
    }
  }
`
